import React, {useEffect} from "react"
import {AppWrapper} from "../components/AppWrapper"
import Footer from "../components/ui/Footer"
import {Header} from "../components/Header"
import {useMobile} from "../hooks/hooks.js"
import firebase from "gatsby-plugin-firebase"
import {ANALYTIC_EVENT_NAMES} from "../constants/analytics"
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Hero} from "../components/ui/Hero";
import {ButtonStore} from "../components/ui/ButtonStore";
import {Background2} from "../components/Background/Background2";
import {SVGWaveFooter} from "../components/Background/SVGWaveFooter";
import {SVGWave} from "../components/ui/SVGWave";


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function IndexPage({location}) {
    const {mobile} = useMobile();
    const {t} = useTranslation();
    // analytics - page viewed event
    useEffect(() => {
        if (!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_service_unavailable_page_viewed)
    }, [firebase])

    return (
        <AppWrapper>
            <div id="background" style={{ placeItems: "center", background: "#102543", height: "100%", color: "#102543" }}>
                <Header location={location} />
                <section className="h-screen relative overflow-hidden">
                    <div className="flex flex-col justify-center h-full z-20 relative">
                        <div className={`text-center flex flex-col flex-nowrap justify-center items-center lg:w-3/3`}>
                            <h1 className="text-center text-[2rem] lg:text-[3.4rem] font-bold text-white drop-shadow-[0px_0px_10px_rgba(0,0,0,0.5)]">{t('service_unavailable.message')}</h1>
                            <h2 className="text-center text-[1rem] lg:text-[1.7rem] text-white my-2 lg:my-6 drop-shadow-[0px_0px_10px_rgba(0,0,0,0.8)]">{t('service_unavailable.soon')}</h2>
                            <div className="flex flex-col md:flex-row gap-4 mt-4 justify-center items-center lg:justify-start">
                                <ButtonStore style={{minWidth: '215px'}} variant="apple"/>
                                <ButtonStore style={{minWidth: '215px'}} fullWidth variant="playstore"/>
                            </div>
                        </div>
                    </div>
                    <div className="absolute -bottom-12 w-full z-10">
                        <SVGWave/>
                    </div>
                        <video
                            autoPlay
                            muted
                            playsInline
                            loop
                            poster="/images/hero/poster1.webp"
                            className="absolute w-full h-full top-0 left-0 z-0 object-cover"
                        >
                            <source src="/images/hero/14-900-crf16-veryslow-pixfmtyuv420.mp4" type="video/mp4"/>
                        </video>
                </section>
                <Background2>
                    <SVGWaveFooter />
                </Background2>
                <Footer />
            </div>
        </AppWrapper>
    )
}
