import * as React from "react";
import formeDown from "./forme-down.png"
import formeUp from "./forme-up.png"

const Background2 = ({ children }: { children?: React.ReactNode }) => {
    return (
        <div
            id="Background2"

            {...{
                style: {
                    backgroundColor: '#fff',
                    backgroundImage: (() => {
                        return `
                    linear-gradient(#9EB6D8 50%, #476797)
                    `
                    })(),
                    display: "grid",
                    gridTemplateRows: "repeat(9, 1fr)",
                    gridTemplateColumns: "repeat(3, 33.33vw)",
                    zIndex: -10,
                    overflow: "hidden",
                },
            }}
        >
            {/* <img src={formeDown} alt="" style={{
                gridArea: "1/3/6/4",
                // margin: "0 -20vh 0 0",
            }} />
            <img src={formeUp} alt="" style={{
                gridArea: "6/1/-1/2",
                placeSelf: "end start",
                marginBottom: "-400px",
            }} /> */}

            <div id=""
                className=""
                {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        display: "grid",
                        zIndex: 10,
                    },
                }}>

                {children}
            </div>
        </div>
    )
}

export { Background2 }